import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet
        title={"Untitled1 | Yofrancisco"}
        meta={[
          {
            name: `description`,
            content: "Untitled1 | Yofrancisco",
          },
          {
            property: `og:title`,
            content: "Untitled1 | Yofrancisco",
          },
          {
            property: `og:description`,
            content: "Untitled1 | Yofrancisco",
          },
          {
            property: `og:type`,
            content: `website`,
          },
        ]}
      >
        <link rel="stylesheet" href="/css/style.css" type="text/css" />

        <link rel="stylesheet" href="/css/homeanimation.css" type="text/css" />
        <script type="text/javascript" src="/js/jquery-1.11.3.min.js"></script>

        <script type="text/javascript" src="/js/TweenMax.js"></script>

        <script type="text/javascript" src="/js/TweenLite.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.5/ScrollMagic.min.js"></script>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.5/plugins/debug.addIndicators.min.js"></script>

        <script
          type="text/javascript"
          src="/js/plugins/animation.gsap.js"
        ></script>

        <script
          type="text/javascript"
          src="/js/jquery.lettering-0.6.1.min.js"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              var controller = new ScrollMagic.Controller();
            `,
          }}
        />
      </Helmet>
      <main>{children}</main>
      <Helmet>
        <script type="text/javascript" src="/test.js"></script>
      </Helmet>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
